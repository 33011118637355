import { StaticImage } from 'gatsby-plugin-image';

import Layout from '../components/Layout';
import ImageGrid from '../components/ImageGrid';

const STATIC_IMAGE_URLS = [
  <StaticImage src='../images/engagements/2018-ar/aEowwcGwytIO.jpg' alt="" />,
  <StaticImage src='../images/weddings/2019-ab/d1DybthCGC_R.jpg' alt="" />,
  <StaticImage src='../images/engagements/2019-ml/MJBAjMzQ84v6.jpg' alt="" />,
  <StaticImage src='../images/proposals/2019-gb/ddK-lRQ2iyRl.jpg' alt="" />,
  <StaticImage src='../images/engagements/2018-ar/WMvsFlv0CqdH.jpg' alt="" />,
  <StaticImage src='../images/engagements/2019-ml/ti5ifVx4fwr6.jpg' alt="" />,
  <StaticImage src='../images/weddings/2019-ab/DqERwkuvnyDh.jpg' alt="" />,
  <StaticImage src='../images/proposals/2019-gb/bAFVgtnZV7jC.jpg' alt="" />,
  <StaticImage src='../images/weddings/2019-ab/4la4QEMHD3Mg.jpg' alt="" />,
  // <StaticImage src='../images/engagements/2018-ar/AQ7Exzg8l4kL.jpg' alt="" />,
  <StaticImage src='../images/engagements/2018-ar/6GwlPZCUL1of.jpg' alt="" />,
  <StaticImage src='../images/engagements/2018-ar/TUELwu4hzLDT.jpg' alt="" />,
  <StaticImage src='../images/engagements/2019-ml/QuM58k2ahPAt.jpg' alt="" />,
];

export default function IndexPage() {
  return (
    <Layout>
      <ImageGrid images={STATIC_IMAGE_URLS} />
    </Layout>
  )
}
