import { Fragment, useState, ReactElement } from 'react';
import { IStaticImageProps } from 'gatsby-plugin-image/dist/src/components/static-image.server';
import { Transition, Dialog } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';

type ImageGridProps = {
  images: ReactElement<IStaticImageProps>[];
}

export default function ImageGrid({ images }: ImageGridProps) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isModalOpen, setModalOpen] = useState(false);

  return (
    <>
      <div className="mx-auto grid gap-5 grid-cols-1 auto-rows-min items-stretch lg:grid-cols-2">
        {images.map((imageComponent, index) => (
          <button key={index} className="w-full aspect-video focus:ring-0" onClick={() => {
            setCurrentIndex(index);
            setModalOpen(true)
          }}>
            {imageComponent}
          </button>
        ))}
      </div>

      <Transition appear show={isModalOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          onClose={() => setModalOpen(false)}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-white" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-fit p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white">
                <div className='text-right'>
                  <button onClick={() => setModalOpen(false)}>
                    <XIcon className="h-8 w-8 text-gray-400" />
                  </button>
                </div>
                <div className="w-fit">
                  {images[currentIndex]}
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}
